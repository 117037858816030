import { useState } from 'react';
import FirstLineActionItemComponent from '../../../container/RiskManagement/MyAssurance/AssuranceFirstLine/FirstLineActionItemComponent';
import s from '../AssuranceCallout.module.css';
import ChecklistDetailsAccordion from '../ChecklistDetailsAccordion/ChecklistDetailsAccordion';
import OverallChart from '../OverallChart/OverallChart';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CalloutType } from '../../../saga/risk.saga';

interface IFLAAssuranceCallout {
  calloutData: any;
  selectedCompTab: CalloutType;
  setSelectedCompTab: (tab: CalloutType) => void;
}
export default function FLAAssuranceCallout({ calloutData, selectedCompTab, setSelectedCompTab }: IFLAAssuranceCallout) {
  const handleChangeCompTab = (tab: CalloutType) => {
    setSelectedCompTab(tab);
  };

  const compTabArr = [
    { label: "Requirement Compliance", key: "requirement", value: 0 },
    { label: "Schedule Compliance", key: "compliance", value: 1 },
  ];

  const breakdownByAssetCols: ColumnsType<{
    assessorDepartment: string;
    plannedProgram: number;
    conductedProgram: number;
    unplannedProgram: number;
    cancelledProgram: number;
    overdueProgram: number;
  }> = [
      {
        title: 'Asset/Dept',
        dataIndex: 'assessorDepartment',
        key: 'assessorDepartment',
        width: 153
      },
      {
        title: 'Planned',
        dataIndex: 'plannedProgram',
        key: 'plannedProgram',
        className: s.rightAlign,
        width: 50,
        render: (text) => <span style={{ display: 'flex', justifyContent: 'flex-end' }}>{text}</span>,
      },
      {
        title: 'Conducted',
        dataIndex: 'conductedProgram',
        key: 'conductedProgram',
        className: s.rightAlign,
        width: 65,
        render: (text) => <span style={{ display: 'flex', justifyContent: 'flex-end' }}>{text}</span>,
      },
      {
        title: 'Unplanned',
        dataIndex: 'unplannedProgram',
        key: 'unplannedProgram',
        width: 64,
        className: s.rightAlign,
        render: (text) => <span style={{ display: 'flex', justifyContent: 'flex-end' }}>{text}</span>,
      },
      {
        title: 'Cancelled',
        dataIndex: 'cancelledProgram',
        key: 'cancelledProgram',
        width: 59,
        className: s.rightAlign,
        render: (text) => <span style={{ display: 'flex', justifyContent: 'flex-end' }}>{text}</span>,
      },
      {
        title: 'Not Conducted',
        dataIndex: 'overdueProgram',
        key: 'overdueProgram',
        width: 85,
        className: s.rightAlign,
        render: (text) => <span style={{ display: 'flex', justifyContent: 'flex-end' }}>{text}</span>,
      },
    ];


  return <>
    <div className={`${s.opuTab} ${s.compTab}`}>
      <ul>
        {compTabArr.map((v, i) => {
          return (
            <li
              key={i}
              onClick={() => {
                handleChangeCompTab(v.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleChangeCompTab(v.value);
                }
              }}
              tabIndex={i}
              className={`${selectedCompTab === v.value ? s.active : ''}`}
            >
              {v.label}
            </li>
          );
        })}
      </ul>
    </div>
    {selectedCompTab === 0 && <>
      <div className={s.calloutValues}>
        <OverallChart overall={calloutData.overall} />
        <FirstLineActionItemComponent type={'calloutActionRequirement'} />
      </div>
      <div className={s.accordionContainer}>
        <h2>Non-Compliance by Assurance Category</h2>
        <ChecklistDetailsAccordion />
      </div>
    </>}
    {selectedCompTab === 1 && <>
      <div className={s.calloutValues}>
        <OverallChart overall={calloutData.overall ?? calloutData.overallPercentage?.value} />
        <FirstLineActionItemComponent type={'calloutActionSchedule'} />
      </div>
      <div className={s.assetBreakdownCalloutContainer}>
        <h2>Breakdown by Assets/Depts</h2>
        <Table
          className="assurance-table fla-asset-breakdown-callout-table"
          rowClassName={(record, index) => index % 2 !== 0 ? s.tableStripe : ''}
          columns={breakdownByAssetCols}
          dataSource={calloutData.assuranceDeptsBreakdown}
          pagination={false}
          tableLayout="fixed"
        />
      </div>
    </>}
  </>
}