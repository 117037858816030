import { useSelector } from 'react-redux';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';

export interface ThirdLineActionItemProps {
  type: string;
}

const ThirdLineActionItemComponent = ({ type }: ThirdLineActionItemProps) => {
  
  const ACAInfo = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.agreedCorrectiveAction', [])
  );
  
  const auditInfo = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.auditPlan', [])
  );

  if (type === 'ACA') {
    return (
      <div className={s.thirdLineActionItems}>
        <div className={`${s.actionItems} ${s.ACAAction}`}>
          <div className={`${s.actionItem} ${s.overdue}`}>
            <div className={s.bar}></div>
            <div>
              <p>Overdue</p>
              <span>{ACAInfo?.overdueAction}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.notAssigned}`}>
            <div className={s.bar}></div>
            <div>
              <p>Not Assigned</p>
              <span>{ACAInfo?.notAssignedAction}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.inProgress}`}>
            <div className={s.bar}></div>
            <div>
              <p>In Progress</p>
              <span>{ACAInfo?.inProgressAction}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.closed}`}>
            <div className={s.bar}></div>
            <div>
              <p>Closed</p>
              <span>{ACAInfo?.closedAction}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={s.thirdLineActionItems}>
        <div className={`${s.actionItems} ${s.auditAction}`}>
          <div className={`${s.actionItem} ${s.planned}`}>
            <div className={s.bar}></div>
            <div>
              <p>Planned</p>
              <span>{auditInfo?.plannedProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.conducted}`}>
            <div className={s.bar}></div>
            <div>
              <p>Conducted</p>
              <span>{auditInfo?.conductedProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.cancelled}`}>
            <div className={s.bar}></div>
            <div>
              <p>Cancelled</p>
              <span>{auditInfo?.cancelledProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.deferred}`}>
            <div className={s.bar}></div>
            <div>
              <p>Deferred</p>
              <span>{auditInfo?.deferredProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.unplanned  }`}>
            <div className={s.bar}></div>
            <div>
              <p>Unplanned</p>
              <span>{auditInfo?.unplannedProgram}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ThirdLineActionItemComponent;