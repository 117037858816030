import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { CHART_COLOR_PALLATE } from "../../../utils/chart.utils";
import { formatScoreCardNumber } from "../../../utils/Number.utils";
import s from "./MftLineChartComponent.module.css";

const MftLineChartComponent = (props: any) => {
  const chart = useRef<am4charts.XYChart>();
  let colorRunnerIdx = 0;
  useEffect(() => {
    if (!isEmpty(props.chartData)) {
      const chartdiv = am4core.create(props.chartId, am4charts.XYChart);
      chart.current = chartdiv;
      const chartCurrent = chart.current;
      if (chartCurrent) {
        if (props.chartData?.data.length > 0) {
          const data = props.chartData.data;
          chartCurrent.data = data;
          chartCurrent.background.fillOpacity = 0;
          chartCurrent.height = am4core.percent(100);
          chartCurrent.cursor = new am4charts.XYCursor();
          chartCurrent.cursor.lineY.disabled = true;
          chartCurrent.zoomOutButton.disabled = true;
          chartCurrent.cursor.behavior = "none";
          customizeDateAxis(chartCurrent);
          customizeValueAxis(chartCurrent);
        }
      }
    }

    // Add data
    return () => {
      chart.current?.dispose();
    };
  }, [props.chartData, props.selectedUnit]);

  useEffect(() => {
    const chartCurrent = chart.current;
    if (chartCurrent) {
      chartCurrent.series.each((values, index) => {
        values.bullets.each((bulletValue, bulletIdx) => {
          bulletValue.adapter.add("tooltipHTML", (adapterValue, target) => {
            const data = target?.tooltipDataItem?.dataContext;
            if (data) {

              const dataJson = JSON.parse(JSON.stringify(data));
              const date = new Date(dataJson.date);
              const dataKey = dataJson[`key${index}`];
              const currentYear = props?.chartData?.cards?.year;
              if (date.getFullYear() === currentYear) {
                return customizeTooltip(dataKey);
              } else {
                return customizeTooltipP4R(dataJson[`tooltip${index}`], dataKey);
              }
            }


          });

        });
        setActiveChart(values);
      });
    }
  }, [props.activeKey, props.selectedUnit, props.chartData]);

  function setActiveChart(values) {
    if (!props.activeKey) {
      return
    }
    const hundredMS = 100;
    setTimeout(() => {
      isKeySeriesActive(props.activeKey, values.dataFields.valueY)
        ? values.show()
        : values.hide();
    }, hundredMS);
  }

  function isKeySeriesActive(activeKey, series) {
    const lastChar = series.substr(series.length - 1);
    return activeKey === props.chartData?.data?.[0]?.[`key${lastChar}`];
  }

  function customizeDateAxis(chartCurrent: am4charts.XYChart) {
    const dateAxis = chartCurrent.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.baseInterval = { timeUnit: "year", count: 1 };
    dateAxis.renderer.labels.template.fill = am4core.color("#ffffffb8");
    dateAxis.renderer.labels.template.fontSize = 11;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.events.on("datavalidated", (ev) => {
      const axis = ev.target;
      const start = axis.positionToDate(0);
      const end = axis.positionToDate(1);
      const current = new Date(start);
      while (current < end) {
        const range = axis.axisRanges.create();
        range.date = current;
        range.endDate = current;
        range.label.dataItem.text = `FY${moment(current).format("YYYY")}`;
        range.label.dx = 120;
        range.grid.strokeOpacity = 0;

        //Iterate
        current.setFullYear(current.getFullYear() + 1);
      }
    });
  }

  function getMinValue() {
    let returnMinValue = 0;
    props.chartData.data.forEach((item: any, idx) => {
      const arr: any = Object.values(item);
      const numArr = arr.filter(function (el) {
        return !isNaN(parseFloat(el)) && isFinite(el);
      });
      const min: any = Math.min(...numArr);
      if (returnMinValue > min) {
        returnMinValue = min;
      }
    });
    return returnMinValue;
  }
  function customizeValueAxis(chartCurrent: am4charts.XYChart) {
    // Create series
    const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.min = getMinValue();
    valueAxis.fontSize = 11;
    for (let idx = 0; idx < props.chartData.charts.length; idx++) {
      createLineChart(chartCurrent, `series${idx}`, valueAxis);
    }
  }

  function getTooltipPrimaryData(dataKey) {
    let returnData = {};
    const cardsData = props.chartData?.cards;
    cardsData?.primaryCards && cardsData?.primaryCards.forEach((item, idx) => {
      if (dataKey === item.key) {
        returnData = item.values[props.selectedUnit];
      }
    });
    return returnData;
  }

  function getTooltipBreakdownData(dataKey) {
    let returnData = {};
    const cardsData = props.chartData?.cards;
    cardsData?.primaryCards && cardsData?.primaryCards.forEach((item, idx) => {
      item.breakdowns &&
        item.breakdowns.forEach((itemBreakdown) => {
          if (dataKey === itemBreakdown.key) {
            returnData = itemBreakdown.values[props.selectedUnit];
          }
        });
    });

    return returnData;
  }

  function tooltipHtml(dataTooltip) {
    return (
      <table className={s.tooltipHtml}>
        <tr className={s.tooltipHtmlYep}>
          <td>
            <p>YEP</p>
          </td>
          <td>
            <p>{dataTooltip?.yep?.currency || ""}</p>
          </td>
          <td>
            <p>{formatScoreCardNumber(dataTooltip?.yep?.value, 2)}</p>
          </td>
          <td>
            <p>{dataTooltip?.yep?.unit || ""}</p>
          </td>
        </tr>
        <tr className={s.tooltipHtmlYet}>
          <td>
            <p>YET</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.currency || ""}</p>
          </td>
          <td>
            <p>{formatScoreCardNumber(dataTooltip?.yet?.value, 2)}</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.unit || ""}</p>
          </td>
        </tr>
      </table>
    );
  }

  function getColorPallete() {
    if (colorRunnerIdx > 4) {
      colorRunnerIdx = 0;
    }
    colorRunnerIdx++;
    return CHART_COLOR_PALLATE[colorRunnerIdx];
  }

  function isPrimaryCards(dataKey) {
    let returnIsPrimaryCards = false;
    const cards = props.chartData.cards;
    cards?.primaryCards && cards?.primaryCards.forEach((item) => {
      if (item.key === dataKey) {
        returnIsPrimaryCards = true;
      }
    });
    return returnIsPrimaryCards;
  }

  function customizeTooltip(dataKey) {
    const dataTooltip: any = isPrimaryCards(dataKey)
      ? getTooltipPrimaryData(dataKey)
      : getTooltipBreakdownData(dataKey);
    const tooltipHTML = tooltipHtml(dataTooltip);
    return renderToStaticMarkup(tooltipHTML);
  }

  function customizeTooltipP4R(p4rValue, dataKey) {
    const dataTooltip: any = isPrimaryCards(dataKey)
      ? getTooltipPrimaryData(dataKey)
      : getTooltipBreakdownData(dataKey);
    const tooltipHTMLP4R = () => (
      <table className={s.tooltipHtml}>
        <tr className={s.tooltipHtmlYep}>
          <td>
            <p>P4R</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.currency || ""}</p>
          </td>
          <td>
            <p>{formatScoreCardNumber(p4rValue, 2)}</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.unit || ""}</p>
          </td>
        </tr>
      </table>
    );

    return renderToStaticMarkup(tooltipHTMLP4R());
  }

  function createLineChart(
    chartCurrent: am4charts.XYChart,
    seriesNo: string,
    valueAxis: am4charts.ValueAxis
  ) {
    const lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
    const circleBullet = new am4charts.CircleBullet();
    const lineColor = getColorPallete();
    circleBullet.circle.stroke = am4core.color("#000000");
    circleBullet.circle.fill = am4core.color(lineColor);
    circleBullet.propertyFields.showTooltipOn = "showTooltip0";
    lineSeries.bullets.push(circleBullet);
    lineSeries.dataFields.valueY = seriesNo;
    lineSeries.dataFields.dateX = "date";
    lineSeries.yAxis = valueAxis;
    lineSeries.stroke = am4core.color(lineColor);
    lineSeries.strokeWidth = 2;
    lineSeries.showOnInit = false;

    const lineTooltip = lineSeries.tooltip;
    if (lineTooltip) {
      lineTooltip.pointerOrientation = "down";
      lineTooltip.getFillFromObject = false;
      lineTooltip.background.fill = am4core.color("#051212");
      lineTooltip.background.opacity = 0.7;
      lineTooltip.background.strokeOpacity = 0;
    }
  }

  return (
    <>
      <div id={props.chartId} className={s.trendChart} />
    </>
  );
};
export default MftLineChartComponent;
