import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Collapse, Progress, Table } from 'antd';
import s from './FindingACADetail.module.css';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';

const FindingACADetail = () => {
  const { Panel } = Collapse;
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const auditTitleTLA = useSelector((state) =>
    get(state, 'risk.assuranceAssetCallout.result.auditTitleTLA', [])
  );

  const statusCount = ({ findingVal, acaVal, overdueVal }: { overdueVal: number, findingVal: number, acaVal: number }) => {
    return (
      <>
        <span className={s.statusCount}><span className={s.statusCountNo}>{findingVal}</span> Findings</span>
        <span className={s.statusCount}><span className={s.statusCountNo}>{acaVal}</span> ACA</span>
        <span className={s.statusCount}><span className={s.statusCountNo}>{overdueVal}</span> Overdue</span>
        </>
    );
  };

const renderCategoryCollapse = (category) => {
  if (category?.length === 0) {
    return <p>No data available for Overdue action item.</p>;
  }

  return (
    category.map((categoryItem, categoryIdx) => (
      <Collapse
        className={s.childrenCollapse}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <img
            alt="arrow"
            src="/img/icon/accordion-arrow-down.svg"
            className={isActive ? s.arrowActive : s.arrow}
          />
        )}
      >
        <Panel
          header={
            <div className={s.childPanelHeader}>
              <span className={s.childPanelSubHeader}>
                Category
              </span>
              <span className={s.checkListName}>
                {categoryItem.category}
              </span>
            </div>
          }
          key={'categoryItem' + categoryIdx}
        >
          {categoryItem.findings.map((finding, findingIdx) => <div className={s.findingsContainer} key={'categoryItem' + categoryIdx + 'finding' + findingIdx}>
            <div className={s.findingBody}>
              <span className={s.childPanelSubHeader}>
                #{findingIdx + 1} Finding
              </span>
              <span className={s.actionDescription}>{finding.finding}</span>
              {finding.acas?.map((aca, capIdx) => <div className={s.findingActionItemContainer} key={'categoryItem' + categoryIdx + 'finding' + findingIdx + "aca" + capIdx}>
                <span className={s.childPanelSubHeader}>
                  #{capIdx + 1} CAP
                  <span style={{ color: 'white' }}>
                    {new Date(aca.dueDate).toLocaleDateString('en-GB', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                </span>
                <hr />
                <span className={s.actionDescription}>{aca.aca}</span>
              </div>)}
            </div>
          </div>)}
        </Panel>
      </Collapse >
    ))
  );
};

const renderClassificationCollapse = (classification) => {
  return (
    classification.map((classificationItem, classIdx) => (
      <Collapse
        className={s.parentCollapse}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <img
            className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
            alt="arrow"
            src="/img/icon/accordion-arrow-down.svg"
          />
        )}
      >
        <Panel
          header={
            <div className={s.panelHeader}>
              <div className={`${s.accordionHeader} ${s.badge} ${s['badge-' + classificationItem.classification?.toLowerCase()]}`}>{classificationItem.classification?.toUpperCase()}</div>
              <div className={s.statusCountContainer}>
                {statusCount({
                  acaVal: classificationItem.acaCount,
                  findingVal: classificationItem.findingCount,
                  overdueVal: classificationItem.overdueCount
                })}
              </div>
            </div>
          }
          key={"classificationItem" + classIdx}
        >
          <div className={activeKeys.includes("classificationItem" + classIdx) ? s.expandedContent : ''}>
            {renderCategoryCollapse(classificationItem.categories)}
          </div>
        </Panel>
      </Collapse>
    ))
  );
};


const handlePanelChange = (keys) => {
  setActiveKeys(keys as string[]);
};

useEffect(() => {
  setActiveKeys([]);
}, [auditTitleTLA]);


return (
  <>
    {(!auditTitleTLA || auditTitleTLA.length === 0 || auditTitleTLA[0].auditTitleTLA === null) ? (
      <div className={s.noResult}>
        <img src="/img/icon/no-result.svg" alt="No results" />
        <h2>No data available!</h2>
      </div>
    ) :
      auditTitleTLA.map((auditTitle, auditTitleIdx) => <Collapse
        className={s.parentCollapse}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <img
            className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
            alt="arrow"
            src="/img/icon/accordion-arrow-down.svg"
          />
        )}
        activeKey={activeKeys}
        onChange={(key) => {
          handlePanelChange(key)
        }}
      >
        <Panel
          header={
            <div className={s.panelHeader}>
              <div className={`${s.accordionHeader}`}>{auditTitle.auditTitle}</div>
            </div>
          }
          key={"auditTitle" + auditTitleIdx}
        >
          <div className={activeKeys.includes("auditTitle" + auditTitleIdx) ? s.expandedContent : ''}>
            {renderClassificationCollapse(auditTitle.classifications)}
          </div>
        </Panel>
      </Collapse>)
      }
  </>
);
};

export default FindingACADetail;