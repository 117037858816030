import { useSelector } from 'react-redux';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';

export interface FirstLineActionItemProps {
  type?: string;
}

const FirstLineActionItemComponent = ({ type }) => {
  const actionsMap = {
    requirement: useSelector((state) => get(state, 'risk.myAssuranceInfo.result.assuranceRequirementCompliance', [])),
    schedule: useSelector((state) => get(state, 'risk.myAssuranceInfo.result.assuranceScheduleCompliance', [])),
    calloutActionRequirement: useSelector((state) => get(state, 'risk.assuranceAssetCallout.result', [])),
    calloutActionSchedule: useSelector((state) => get(state, 'risk.assuranceAssetCallout.result', [])),
  };

  const actionItemsConfig = {
    requirement: [
      { label: 'Overdue', key: 'overdueAction', barColor: s.overdue },
      { label: 'Not Assigned', key: 'notAssignedAction', barColor: s.notAssigned },
      { label: 'In Progress', key: 'inProgressAction', barColor: s.inProgress },
      { label: 'Closed', key: 'closedAction', barColor: s.closed },
    ],
    schedule: [
      { label: 'Planned', key: 'plannedProgram', barColor: s.planned },
      { label: 'Conducted', key: 'conductedProgram', barColor: s.conducted },
      { label: 'Unplanned', key: 'unplannedProgram', barColor: s.unplanned },
      { label: 'Cancelled', key: 'cancelledProgram', barColor: s.cancelled },
      { label: 'Overdue', key: 'overdueProgram', barColor: s.overdue, style: { gridColumn: 'span 2' } },
    ],
    calloutActionRequirement: [
      { label: 'Overdue', key: 'actionItems.overdueAction', barColor: s.overdue },
      { label: 'Not Assigned', key: 'actionItems.notAssignedAction', barColor: s.notAssigned },
      { label: 'In Progress', key: 'actionItems.inProgressAction', barColor: s.inProgress },
      { label: 'Closed', key: 'actionItems.closedAction', barColor: s.closed },
    ],
    calloutActionSchedule: [
      { label: 'Planned', key: 'plannedProgram', barColor: s.planned },
      { label: 'Conducted', key: 'conductedProgram', barColor: s.conducted },
      { label: 'Unplanned', key: 'unplannedProgram', barColor: s.unplanned },
      { label: 'Cancelled', key: 'cancelledProgram', barColor: s.cancelled },
      { label: 'Overdue', key: 'overdueProgram', barColor: s.overdue, style: { gridColumn: 'span 2' } },
    ],
  };

  const renderActionItems = (actions, type) => (
    <div className={type === 'requirement' || type === 'calloutActionRequirement' ? `${s.actionItems} ${s.requirementAction}` : `${s.actionItems} ${s.scheduleAction}`}> 
      {actionItemsConfig[type].map(({ label, key, barColor, style }, index) => (
        <div key={index} className={`${s.actionItem} ${barColor}`} style={style || {}}>
          <div className={s.bar}></div>
          <div>
            <p>{label}</p>
            <span>{get(actions, key, '--')}</span>
          </div>
        </div>
      ))}
    </div>
  );

  if (actionsMap[type]) {
    return (
      <div className={s.actionItemsSection}>
        <div className={s.actionItemsHeader}>{type.includes('schedule') ? 'Assurance Program' : 'Action Items'}</div>
        {renderActionItems(actionsMap[type], type)}
      </div>
    );
  }

  return null;
};

export default FirstLineActionItemComponent;