import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { SUSTAIN_COLOR } from "../../../constants/mft.constants";
import { formatScoreCardNumber, isEvenNumber } from "../../../utils/Number.utils";
import s from "./MftWaveChartComponent.module.css";

const MftWaveChartComponent = (props: any) => {
  const chart = useRef<am4charts.XYChart>();
  useEffect(() => {
    if (!isEmpty(props.chartData)) {
      const chartdiv = am4core.create(props.chartId, am4charts.XYChart);
      chart.current = chartdiv;
      const chartCurrent = chart.current;
      if (chartCurrent) {
        if (props.chartData?.data.length > 0) {
          const data = props.chartData.data;
          chartCurrent.data = data;
          chartCurrent.background.fillOpacity = 0;
          chartCurrent.height = am4core.percent(100);
          chartCurrent.cursor = new am4charts.XYCursor();
          chartCurrent.cursor.lineY.disabled = true;
          chartCurrent.zoomOutButton.disabled = true;
          chartCurrent.cursor.behavior = "none";
          customizeDateAxis(chartCurrent);
          customizeValueAxis(chartCurrent);
        }
      }
    }

    // Add data
    return () => {
      chart.current?.dispose();
    };
  }, [props.chartData, props.selectedUnit]);

  useEffect(() => {
    const chartCurrent = chart.current;
    if (chartCurrent) {
      chartCurrent.series.each((values, index) => {
        const dataSetIndex = getDataSetIndex(index);
        values.bullets.each((bulletValue, _bulletIdx) => {
          bulletValue.adapter.add("tooltipHTML", (_adapterValue, target) => {
            const data = target.tooltipDataItem.dataContext;
            const dataJson = JSON.parse(JSON.stringify(data));
            const date = new Date(dataJson.date);
            const currentYear = props.chartData.cards.year;
            // sustainability-gne
            const dataEvenNum = isEvenNumber(index) ? 0 : 1;
            const dataKey = dataJson[`key${dataSetIndex}`];
            const dataKeyLabel = dataJson[`key${dataKey}${dataEvenNum}`];
            const dataP4R = dataJson[`tooltip${dataKey}${dataKeyLabel}`];
            switch (true) {
              case date.getFullYear() === currentYear && isEvenNumber(index):
                return customizeTooltip(dataKey);
              case date.getFullYear() === currentYear && !isEvenNumber(index):
                return ''
              case date.getFullYear() === currentYear + 3:
                return customizeSecondTooltip(isEvenNumber(index));
              default:
                return customizeTooltipP4R(dataP4R, dataKey);
            }
          });
        });
        setChartPointer(values, index)
        setActiveChart(values, dataSetIndex)
      });
    }
  }, [props.activeKey, props.selectedUnit, props.chartData]);
  function setChartPointer(values, index) {
    const lineTooltip = values.tooltip;
    if (lineTooltip) {
      lineTooltip.pointerOrientation = isEvenNumber(index) ? "down" : "up";
    }
  }

  function setActiveChart(values, dataSetIndex) {
    if (!props.activeKey) {
      return
    }
    const chartsdata = props?.chartData?.charts[dataSetIndex] || 0;
    const chartDataKey = chartsdata?.key;
    isKeySeriesActive(props.activeKey, chartDataKey) ? values.show() : values.hide();
  }
  function getDataSetIndex(index) {
    if (!isEvenNumber(index)) {
      const num = index - 1
      return (num / 2)
    } else {
      return index / 2
    }
  }

  function isKeySeriesActive(activeKey, series) {
    return activeKey === series;
  }

  function customizeDateAxis(chartCurrent: am4charts.XYChart) {
    const dateAxis = chartCurrent.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.baseInterval = { timeUnit: "year", count: 1 };
    dateAxis.renderer.labels.template.fill = am4core.color("#ffffffb8");
    dateAxis.renderer.labels.template.fontSize = 11;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.events.on("datavalidated", (ev) => {
      const axis = ev.target;
      const start = axis.positionToDate(0);
      const end = axis.positionToDate(1);
      const current = new Date(start);
      while (current < end) {
        const range = axis.axisRanges.create();
        range.date = current;
        range.endDate = current;
        range.label.dataItem.text = `FY${moment(current).format("YYYY")}`;
        range.label.dx = 120;
        range.grid.strokeOpacity = 0;

        //Iterate
        current.setFullYear(current.getFullYear() + 1);
      }
    });
  }

  function getMinValue() {
    let returnMinValue = 0;
    props.chartData?.data?.forEach((item: any) => {
      const arr: any = Object.values(item);
      const numArr = arr.filter(function (el) {
        return !isNaN(parseFloat(el)) && isFinite(el);
      });
      const min: any = Math.min(...numArr);
      if (returnMinValue > min) {
        returnMinValue = min;
      }
    });
    return returnMinValue;
  }
  function customizeValueAxis(chartCurrent: am4charts.XYChart) {
    // Create series
    const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.min = getMinValue();
    valueAxis.fontSize = 11;
    valueAxis.baseValue = -1000;
    for (let idx = 0; idx < props.chartData.charts.length; idx++) {
      const dataKey = props.chartData.data[idx][`key${idx}`];
      const firtLineKey = props.chartData.data[idx][`key${dataKey}0`];
      const secondLineKey = props.chartData.data[idx][`key${dataKey}1`];

      createLineChart(
        chartCurrent,
        `series${dataKey}${firtLineKey}`,
        valueAxis,
        "Emissions"
      );
      createLineChart(
        chartCurrent,
        `series${dataKey}${secondLineKey}`,
        valueAxis,
        "Reduction"
      );
    }
  }

  function getTooltipPrimaryData(dataKey) {
    let returnData = {};
    const cardsData = props.chartData?.cards;
    cardsData?.primaryCards.forEach((item) => {
      if (dataKey === item.key) {
        returnData = item.values[props.selectedUnit];
      }
    });
    return returnData;
  }

  function getTooltipBreakdownData(dataKey) {
    let returnData = {};
    if (props.chartData?.cards?.primaryCards) {
      props.chartData?.cards?.primaryCards.forEach((item) => {
        item.breakdowns.forEach((itemBreakdown) => {
          if (dataKey === itemBreakdown.key) {
            returnData = itemBreakdown.values[props.selectedUnit];
          }
        });
      });
    }


    return returnData;
  }

  function tooltipHtml(dataTooltip) {
    return (
      <table className={s.tooltipHtml}>
        <tr className={s.tooltipHtmlYep}>
          <td>
            <p>YEP</p>
          </td>
          <td>
            <p>{dataTooltip?.yep?.currency || ""}</p>
          </td>
          <td>
            <p>{formatScoreCardNumber(dataTooltip?.yep?.value, 2)}</p>
          </td>
          <td>
            <p>{dataTooltip?.yep?.unit || ""}</p>
          </td>
        </tr>
        <tr className={s.tooltipHtmlYet}>
          <td>
            <p>YET</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.currency || ""}</p>
          </td>
          <td>
            <p>{formatScoreCardNumber(dataTooltip?.yet?.value, 2)}</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.unit || ""}</p>
          </td>
        </tr>
      </table>
    );
  }

  function tooltipSecondHtml(isEven) {
    const tooltipLabel = {
      true: "Emissions",
      false: "Emissions after reduction",
    };
    const tooltipLabelClass = {
      true: s.emissionLabel,
      false: s.reductionLabel,
    };
    return (
      <div className={`${s.tooltipHtml} ${tooltipLabelClass[isEven]}`}>
        {tooltipLabel[isEven]}
      </div>
    );
  }

  function isPrimaryCards(dataKey) {
    let returnIsPrimaryCards = false;
    if (props.chartData?.cards.primaryCards) {
      props.chartData?.cards?.primaryCards?.forEach((item) => {
        if (item.key === dataKey) {
          returnIsPrimaryCards = true;
        }
      });
    }

    return returnIsPrimaryCards;
  }

  function customizeSecondTooltip(isEven) {
    const tooltipHTML = tooltipSecondHtml(isEven);
    return renderToStaticMarkup(tooltipHTML);
  }
  function customizeTooltip(dataKey) {
    const dataTooltip: any = isPrimaryCards(dataKey)
      ? getTooltipPrimaryData(dataKey)
      : getTooltipBreakdownData(dataKey);

    const tooltipHTML = tooltipHtml(dataTooltip);
    return renderToStaticMarkup(tooltipHTML);
  }

  function customizeTooltipP4R(p4rValue, dataKey) {
    const dataTooltip: any = isPrimaryCards(dataKey)
      ? getTooltipPrimaryData(dataKey)
      : getTooltipBreakdownData(dataKey);
    const tooltipHTML = () => (
      <table className={s.tooltipHtml}>
        <tr className={s.tooltipHtmlYep}>
          <td>
            <p>P4R</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.currency || ""}</p>
          </td>
          <td>
            <p>{formatScoreCardNumber(p4rValue, 2)}</p>
          </td>
          <td>
            <p>{dataTooltip?.yet?.unit || ""}</p>
          </td>
        </tr>
      </table>
    );

    return renderToStaticMarkup(tooltipHTML());
  }


  function createLineChart(
    chartCurrent: am4charts.XYChart,
    seriesNo: string,
    valueAxis: am4charts.ValueAxis,
    lineKey: string
  ) {
    const lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
    const circleBullet = new am4charts.CircleBullet();
    circleBullet.circle.hidden = true;
    circleBullet.propertyFields.showTooltipOn = "showTooltip0";
    lineSeries.bullets.push(circleBullet);
    lineSeries.fill = am4core.color(SUSTAIN_COLOR[lineKey]);
    lineSeries.dataFields.valueY = seriesNo;
    lineSeries.dataFields.dateX = "date";
    lineSeries.yAxis = valueAxis;
    lineSeries.stroke = am4core.color(SUSTAIN_COLOR[lineKey]);
    lineSeries.strokeWidth = 2;
    lineSeries.showOnInit = false;
    lineSeries.fillOpacity = 0.3;

    const lineTooltip = lineSeries.tooltip;
    if (lineTooltip) {
      lineTooltip.getFillFromObject = false;
      lineTooltip.background.fill = am4core.color("#051212");
      lineTooltip.background.opacity = 0.7;
      lineTooltip.background.strokeOpacity = 0;
    }
  }

  return (
    <>
      <div id={props.chartId} className={s.trendChart} />
    </>
  );
};
export default MftWaveChartComponent;
